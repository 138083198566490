
:root {
    --side-nav-width: 20%;
    --secondary-bg-clr: #EBF3F9;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px transparent;
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	background-color: #c3e5ff;
	border-radius: 2px;
}


.signup-form input {
    border-radius: 12px;
    background: #F1F1F1;
    padding: .6rem 1rem;
    color: #000;
}

.signup-form button {
    border-radius: 12px;
}

.signup-form input:focus {
    border: transparent;
    outline: transparent;
}

#side-nav {
    width: var(--side-nav-width);
    border: 1px solid #DEDEDE;
    background: var(--card_BG, #FDFDFD);
}

#nav-section {
    position: relative;
    top: 0;
    bottom: 0;
    flex: 0 0 20%;
    background: var(--card_BG, #FDFDFD);
}

#nav-heading {
    font-size: 25px;
}

header {
    position: fixed;
    left: var(--side-nav-width);
    right: 0;
    background: #FDFDFD;
    border-bottom: 1px solid #85858550;
    z-index: 100;
}

.bottom-line {
    border-bottom: 1px solid #DEDEDE;
}

#header-section {
    right: 0;
    background: #FDFDFD;
}

.active-mobile {
    color: rgba(0, 137, 245, 0.90) !important;
}

.dashboard {
    flex: 1;
    background: white;
    width: 100%;
    padding: 20px 24px;
}

#d-navigator-heading {
    color: #212B36;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#dashboard-navigation {
    margin-top: .5rem;
}

#main-contianer {

    width: 100%;
    border-radius: 10px;
    background: #FDFDFD;
}

#stations-section {}

#stations-div {
    flex: 0 0 30%;
    padding: 20px 24px;
}

#stations-div>h2 {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 1rem;
}

#chargers-map {
    flex: 1;
    height: 100%;
}

#search-filter {
    margin-bottom: 1rem;
}

#search-stations>input {
    background: #F6F6F6;
    color: rgba(134, 134, 139, 1);
    color: var(--Icon-Main-Color, #86868B);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#search-stations>input:hover {
    outline: transparent;
    border: none;
}

#search-stations {
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--Grey-300, #DFE3E8);
    background: #F6F6F6;
    padding: 4px 60px 4px 5px;
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 2.3rem;
}

#search-stations>span {
    color: rgba(134, 134, 139, 1);
    margin-right: .3rem;
    font-size: 1.3rem;
}

#filter-btn {
    border: 1px solid var(--Grey-300, #DFE3E8);
    background: #F6F6F6;
    color: #86868B;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 3px 2px 4px 7px;
    height: 2.3rem;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-left: 1rem;
}

#filter-btn>.material-symbols-outlined {
    font-size: 1.3rem;
}

#station-card {
    width: 100%;
    height: 141px;
    border-radius: 5px;
}

#card-body {
    padding-left: 1rem;
    flex: 1;
}

#station-card img {
    max-width: 100%;
    height: auto;
    flex: 0 0 auto;
}

#card-body h3 {
    color: var(--Grey-800, #212B36);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#card-address {
    color: var(--Grey-700, #454F5B);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#body-details span {
    color: rgba(0, 120, 215, 1);
    font-size: 1.2rem;

}

#body-details div {
    display: flex;
    align-items: center;

}

#body-details>div>small {
    color: var(--Grey-700, #454F5B);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: .3rem;

}

.avalibility-btn {
    display: flex;
    width: 5rem;
    height: 1.4rem;
    padding: 4px 15px 4px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: var(--Green-Light, #99EFCB);
    color: var(--Green-Dark, #017947);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: .5rem 0;
    margin-bottom: 20px;
}

.non-avalibility-btn {
    display: flex;
    width: 5rem;
    height: 1.4rem;
    padding: 4px 15px 4px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: var(--Green-Light, rgba(248, 79, 86, 1));
    color: var(--Green-Dark, rgba(223, 227, 232, 1));
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: .5rem 0;
    margin-bottom: 20px;
}

#rating-div span {
    color: rgba(244, 207, 14, 1);
    font-size: 1rem;
}

#rating-div {
    color: var(--Grey-700, #454F5B);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#rating-div>div:first-of-type {
    display: flex;
    align-items: center;
}

#rating-small {
    font-size: 10px;
}

#station-ul li {
    margin-bottom: 1rem;
}

#station-ul {
    min-height: 500px;
}

#card-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Optional: Show ellipsis (...) for overflowed text */
    max-width: 68%;
    display: block;
}

#card-address-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Optional: Show ellipsis (...) for overflowed text */
    max-width: 35%;
    display: block;
}

#charger-heading h2 {
    color: var(--Grey-800, #212B36);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.charging-station-image {
    flex: 0 0 50%;
}

.charging-station-image img {
    width: 100%;
    height: 100%;

}

.admin-dashboard-container {
    padding: 1.2%;
    border: "none";
}

.Doughnut-container {

    margin-top: 70px;
    margin-left: 60px;
}

.dough-label {
    content: "";
    width: 23px;
    height: 23px;
    margin-right: 2px;
    display: inline-block;

}

.BarCharts-container {
    height: 1100px;
    width: 550px;
}

.dashboard {

    height: 400px;
    top: 107px;
    left: 26px;
    border-radius: 10px;

}

.energyConsumtionChart_and_energyconsumtionDetails_separator {
    content: "";
    height: 250px;
    width: 1px;
    background-color: #CBD4C8;
    margin-left: 80px;
    display: inline-block;
}

.revenue_session_energy_separator {
    content: "";
    width: 1px;
    height: 94px;
    background-color: #CBD4C8;
    display: inline-block;
}

.Online_Faulty_Chargers_separator {
    width: 1px;
    height: 80px;
    background-color:
        #CBD4C8;
    display: inline-block;

}

.Reviews_rating_separator {
    width: 1px;
    height: 220px;
    background-color:
        #CBD4C8;
    margin-top: 40px;
    display: inline-block;


}

.tabs_underline {
    width: auto;
    margin-bottom: 20px;
    margin-left: 10px;
    height: 1px;
    background-color:
        #CBD4C8;

    display: inline-block;

}

.tablist {
    display: flex;
    border: none;
}

.tab {
    outline: none;
    margin: 10px;
    background-color: "#017947";
    cursor: pointer;

}

.Active {
    content: "";


    margin-left: 10px;
    border-bottom: 4px solid #0078D7;
    margin-bottom: 0px;
    display: inline-block;
}

.heading_row {
    padding: 20px;
    text-align: left;
    background-color: #EBF1F6;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}
.heading_row_ticket{

    font-weight: 500;
    text-align: left;
    font-size: 14px ;
    margin-bottom: 10px;
}
.item {
    padding: 10px 15px;
    border-bottom: 1px solid #CBD5E1;
}

.ActiveIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 100%;
    background-color: #0078D7;


}

.InActiveIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 100%;
    background-color: #86868B;
}

.lineIcon {
    width: 1px;
    height: 30px;
    background-color:
        #86868B;
    margin-left: 12px;
    margin-bottom: 3px;
    margin-top: 3px;

}

.newUserInfoSeparator {
    width: 1px;
  
    background-color:
        #86868B;
    height: 60px;
    
}

.ticket_heading_row {
    padding: 20px;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
}

.ticket_items {
    padding: 20px;
}

.ratingbar {
    width: 200px;

    background-color:
        #C7DAEA;
    height: 5px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 20px;
}

.bar {
    height: 100%;
    background-color: #0078D7;

    border-radius: 20px;
}

.PaginationRow {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #CBD5E1;
    grid-column-start: 1;
    grid-column-end: 6;

}

.PaginationRow2 {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #CBD5E1;
    grid-column-start: 1;
    grid-column-end: 8;
}

.PaginationRow3 {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #CBD5E1;
    grid-column-start: 1;
    grid-column-end: 9;
}
.item3{
    padding-left: 15px; font-size: 12px;  font-weight: 500;  padding-right: 15px; padding-top: 10px; padding-bottom: 10px
}

@media screen and (min-width: 1536px) {
    .responsive{
        
        width: "auto";
        /* margin-left: 0%; */
    }
    .reponsive2{
       width: 80%;
    }
}
@media screen and (min-width: 350px) and (max-width:1400px) {
    .responsive{
        
        width: 100%

        
    }
    .reponsive2{
          width: auto;
    }
}
.slick-prev:before{
    content: url(/public/arrow_backward_ios.png);
    color: #000;
}
.slick-next:before{
    content: url(/public/arrow_forward_2_ios.png);
    color: #000;
  
}
.customInput:focus{
    outline: none;
}
.ActionBtnItems{
    cursor: pointer;
    
    
}
.item2{
 
    padding: 10px 15px;
    font-size: 14px;
    border-bottom: 1px solid #CBD5E1;
    margin-left: 10px;
}
.custom-dropdown{
    background-color: white;
    border:1px solid black;
    border-radius: 5px;
    position: absolute;
    padding: 10px;

}
.dropdown{
    content: "";
    position: absolute;
    right: 50px;
    padding: 5px;
    border: 1px solid #CBD5E1; 
}
.NotificationSeparator{
    height: 1px;
    width: 250px;
    background-color: gray;

    display: inline-block;
}
.table-data > img{
    height: 3rem;
}
.btn {
   padding: 12px 20px; border-radius: 7px
  }
  .btn-blue {
    background-color: #0078D7; color: #fff; font-family: "Roboto", sans-serif;
  }
  .btn-white {
    background-color: #fff; color: #454F5B; border: #C4CDD5 solid 1px; font-family: "Roboto", sans-serif;
  }
  .inputicon { position: relative;}
  .inputicon input { padding-left: 45px; padding-right: 35px; border: #D1D5DB solid 1px;}
.inputicon .keyimg{ position: absolute; left: 6px; top: 6px; }
.inputicon .eyeimg { position: absolute; right: 6px; top: 14px; }
  .custom-label{ color: #535151; font-size: 18px; font-weight: 600; font-family: "Roboto", sans-serif;}
  .form-control {   background-color: #fff; height: 48px; border: #919EAB solid 1px;}
.d-inline-block { display: inline-block;}
.card-custom{ border-radius: 10px; padding: 25px; background-color: #fff;} 
.custom-heading-2 {font-size: 28px; font-weight: 600; color: #000; margin: 0px;  font-family: "Roboto", sans-serif;} 
.closeimg { float: right; margin-top: 5px;}
.profile-section-block { display: flex; align-items: center;}
.profile-section-block .profile-section-img img {width: 119px; height: 119px; border-radius: 500px;}

.profile-section-content {  display: flex;  margin-left: 25px;
    flex-direction: column;}
.profile-section-content h3 { line-height: normal; font-size: 26px; margin-bottom: 5px; color: #000; font-weight: 600;  font-family: "Roboto", sans-serif;   }
.profile-section-content small { font-size: 18px; color: #535151; font-family: "Roboto", sans-serif; font-weight: 600; line-height: normal;}

.profiledropdown { position: absolute;}
.customdropdown { background-color: #fff; position: absolute; }
.profile-imgblock {text-align: center; margin-top: 15px;}
.profile-imgblock img {width: 50px; margin: auto;}
.profile-imgblock-content { text-align: center; margin-top: 11px;}
.profile-imgblock-content h3 {font-size: 16px; font-weight: 600; font-family: "Roboto", sans-serif; margin: 0px;}
.profile-imgblock-content .smalltext {font-size: 12px; color: #454F5B; font-weight: 600; font-family: "Roboto", sans-serif}

.custom-sectionbordertop { border-top: #cacaca solid 1px; margin-right: 35px; padding-top: 5px; padding-bottom: 5px;}
.custom-sectionbordertop ul li a {color: #535151; font-weight: 500; font-size: 14px; font-family: "Roboto", sans-serif;}
.custom-sectionbordertop .title-heading {color: #4F4D4D ; font-weight: 500; font-size: 12px; font-family: "Roboto", sans-serif;}
.custom-sectionbordertop .timelog{color: #4F4D4D ; font-weight: 400; font-size: 12px; font-family: "Roboto", sans-serif; }
.table-heading {font-size: 16px; font-weight: bold; font-family: "Roboto", sans-serif; text-align: left; }
.table-data {  font-size: 14px; text-align: left;}
.roboto{
    font-family: "Roboto", sans-serif; 
}
ul.tablist li { font-size: 16px; font-weight: 500; color: #454F5B; font-family: "Roboto", sans-serif; } 
.fs-32px{ font-size: 32px;}
.custom-table { border: #CBD5E1 solid 1px; }
